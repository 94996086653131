<template>
  <div>
    <ShiftsTable actionType="accept" />
  </div>
</template>

<script>
import ShiftsTable from "@candidate/ShiftsDirectTable";

export default {
  components: {
    ShiftsTable,
  },
};
</script>
